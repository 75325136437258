import { styled } from 'styled-components';

export const HeroBackgroundHolder = styled.div`
  position: absolute;
  transform: translateZ(0);
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeroBackgroundImageHolder = styled.div`
  width: 100%;
  height: 100%;
`;
